import React from "react";
import { Link } from "react-router-dom";
import { RecordItem } from "../../components/RecordItem";
import { TODAY } from "../../constants";

export const MainCoach = (
  {
    list = [],
    locationList,
    meetingPlaceList,
  }) => {

  console.log('list', list)

  return (
    <>
      {/*ССЫЛКА НА КАЛЕНДАРЬ*/}
      {/*<div className="kant-record__calendar-link">*/}
      {/*  <Link to='/calendar'>Перейти в календарь</Link>*/}
      {/*</div>*/}

      {(list?.schedule && list?.schedule.length > 0) ? list?.schedule?.map((el, i) => {
        return (
          <div key={i}>
            <h2 className="kant-record__title">{el.PeriodDay}</h2>
            {el.data.length && el.data.map((element, index) => {
              return (
                <RecordItem
                  key={`itemSchedule` + index}
                  item={element}
                  isToday={el.PeriodDay === TODAY}
                  isFirst={index === 0 && i === 0}
                  locationList={locationList}
                  meetingPlaceList={meetingPlaceList}
                />
              )
            })}
          </div>
        )
      })
        : <div className='emptyList'>элементов не обнаружено</div>
      }
    </>
  )
}
