import React, { useEffect, useState } from "react";
import useRecordContext from "../../context/recordContext";
import { auth, auth_admin, auth_ski_patrol } from "../../api/auth";
import { getLocationEventList } from "../../api/location-event";
import { parseCookies } from "nookies"
import { Loader } from "../../components/Loader";
import "./index.scss"
import { MainAdmin } from "../MainAdmin";
import { MainCoach } from "../MainCoach";
import { MainSkiPatrol } from "../MainSkiPatrol";
import useAuthContext from '../../context/authContext'

export const Main = () => {
  const {authData, setAuthData} = useAuthContext();
  const {recordPage, setRecordPage} = useRecordContext();
  const [list, setList] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [error, setError] = useState('');
  const [locationList, setLocationList] = useState([]);
  const [meetingPlaceList, setMeetingPlaceList] = useState([]);
  const [personRole, setPersonRole] = useState('');

  useEffect(() => {
    const cookies = parseCookies();
    init(cookies);
  }, []);

  useEffect(() => {
    getLocationEventList()
      .then((response) => {
        const locationOptions = response.data.filter(item => item.TypeLocation === 'Место проведения');
        const meetingOptions = response.data.filter(item => item.TypeLocation === 'Место встречи');

        setLocationList(locationOptions);
        setMeetingPlaceList(meetingOptions);
      });
  }, [])

  const init = (cookies) => {
    if (cookies) {
      let params = JSON.parse(cookies?.authData);

      switch (params.role) {
        case 'coach':
          authMethod(auth, params)
          break
        case 'admin':
          authMethod(auth_admin, params)
          break
        case 'ski_patrol':
          authMethod(auth_ski_patrol, params)
          break
      }
    }
  }

  const authMethod = (method, params) => {
    setPersonRole(params.role)
    setIsLoad(true);
    method(params)
      .then((response) => {
        if (response.data.Success) {
          console.log('response.data', response.data);
          setList(response.data)
        } else {
          throw new Error(`The response return not success: Texterror ${response.data.TextError}`)
        }
        setIsLoad(false);
      })
      .catch((error) => {
        setIsLoad(false);
        setError('Ошибка сервера');
        console.error(error);
      });
  }

  return (
    <main className="kant-main">
      {error && <div className="kant-main__error">{error}</div>}
      {isLoad && <Loader/>}
      {personRole === 'coach' && (
        <MainCoach
          list={list}
          locationList={locationList}
          meetingPlaceList={meetingPlaceList}
        />
      )}
      {personRole === 'admin' && (
        <MainAdmin
          list={list}
          locationList={locationList}
          meetingPlaceList={meetingPlaceList}
        />
      )}
      {personRole === 'ski_patrol' && <MainSkiPatrol list={list}/>}
    </main>
  )
}
