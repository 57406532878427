import { Button, IconButton, Typography } from '@material-ui/core'
import './index.scss'
import { CloseIcon } from '../icons/CloseIcon'
import { useHistory, useParams } from 'react-router'
import { getSchedule, processSchedule } from '../../api/schedule'
import { useEffect, useState } from 'react'
import moment from 'moment'

const days = [
  {title: 'Понедельник', name: 'UF_MONDAY'},
  {title: 'Вторник', name: 'UF_TUESDAY'},
  {title: 'Среда', name: 'UF_WEDNESDAY'},
  {title: 'Четверг', name: 'UF_THURSDAY'},
  {title: 'Пятница', name: 'UF_FRIDAY'},
  {title: 'Суббота', name: 'UF_SATURDAY'},
  {title: 'Воскресенье', name: 'UF_SUNDAY'},
]

export const CoachRequestDetail = ({noHeader}) => {
  const history = useHistory()
  const {id} = useParams()
  const [schedule, setSchedule] = useState(null)

  const sendSchedule = async () => {
    console.log('send schedule')
    const res = await processSchedule({scheduleId: id, processed: true})
    if(res.success) {
      history.push('/coach_requests/')
    } else console.log('error', res)

  }

  const formatDateTime = (date) => {
    return moment(date).format('DD.MM.YY HH:mm')
  }

  useEffect(() => {
    (async () => {
      const res = await getSchedule(id)
      if (res && res.data.length > 0) {
        console.log(res)
        setSchedule(res.data[0])
      }
    })()
  }, [])

  return (
    <div className='root'>
      <IconButton
        className='close'
        onClick={() => history.push('/coach_requests/')}
      >
        <CloseIcon/>
      </IconButton>
      <div>
        <Typography className='title'>{schedule?.WORKOUT_CODE === 'group' ? 'Групповая тренировка' : 'Индивидуальная тренировка'}</Typography>
        <span className='time'>Заявка от {formatDateTime(schedule?.UF_DATE_CREATE)}</span>

        <div className='group'>
          <Typography className='group_label'>Группа</Typography>
          <Typography className='group_desc'>{schedule?.UF_DESCRIPTION}</Typography>
        </div>

        <div className='group'>
          <Typography className='group_label'>Период</Typography>
          <Typography className='group_desc'>{schedule?.UF_PERIOD}</Typography>
        </div>

        <div className='group'>
          <Typography className='group_label'>Время тренировки</Typography>
          <div className='days'>
            {days.map((day, index) =>
              <div className='day'>
                <div>{day.title}</div>
                <div>{schedule?.[day.name]}</div>
              </div>
            )}
          </div>
        </div>

        <div className='group'>
          <Typography className='group_label'>Комментарий</Typography>
          <Typography className='group_desc'>{schedule?.UF_COMMENT}</Typography>
        </div>
      </div>

      <div style={{display: 'none'}}>
        <Typography>
          Отмечено как обработанное 12.06.21 19:48
        </Typography>
      </div>

      {schedule?.UF_PROCESSED === "0"
        ? <div className='button'>
            <Button onClick={sendSchedule}>
              <Typography>
                Отметить как обработанное
              </Typography>
            </Button>
          </div>
        : <div className='processed'>
            <Typography>
              Отмечено как обработанное {formatDateTime(schedule?.UF_DATE_APPROVE)}
            </Typography>
          </div>
      }


    </div>
  )
}