import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './index.scss'
import logo from '../../images/logo.png'
import { HeaderBurgerIcon } from '../icons/HeaderBurgerIcon'
import { IconButton } from '@material-ui/core'
import { HeaderDrawer } from '../HeaderDrawer'
import { CloseIcon } from '../icons/CloseIcon'
import { useStyles } from '../HeaderDrawer/styles'
import { destroyCookie } from 'nookies'
import { useHistory } from 'react-router'
import useAuthContext from '../../context/authContext'
import classNames from 'classnames'

export const Header = ({noMenu = false, role}) => {
  const [openHeaderMenu, setOpenHeaderMenu] = useState(false)
  const classes = useStyles()
  const {setAuthData} = useAuthContext();
  const history = useHistory()

  const toggleDrawer = (open) => {
    setOpenHeaderMenu(open)
  }

  const logout = () => {
    /** для удаления cookies нужно, чтобы их путь соответствовал созданию */
    destroyCookie({}, 'authData', {path: '/'})
    setAuthData(null)
    history.push(`/login`)
  }

  return (
    <header className="kant-header">
      <Link to={'/'} className={classNames("kant-logo", noMenu && "kant-logo_not-only")}>
        <img src={logo}/>
      </Link>
      {!noMenu &&
      <>
        <IconButton
          style={{paddingRight: 0}}
          onClick={() => setOpenHeaderMenu(true)}
        >
          <HeaderBurgerIcon/>
        </IconButton>

        <IconButton
          className={classes.closeButton}
          onClick={logout}
        >
          <CloseIcon/>
        </IconButton>
      </>
      }

      <HeaderDrawer
        open={openHeaderMenu}
        toggleDrawer={toggleDrawer}
        role={role}
      />
    </header>
  )
}