import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, ThemeProvider, Typography } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { AccordSummarytheme, fontTheme, useStyles } from "./style";
import { CalendarTable } from "../CalendarTable";

export const CalendarAccordion = ({children, coachSchedule = null, date, month}) => {
  const classes = useStyles()
  return (
    <Accordion className={classes.root}>
      <ThemeProvider theme={AccordSummarytheme}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon className={classes.accordIcon}/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordHeader}
        >
          <Typography className={classes.heading}>
            {children.month}
          </Typography>
        </AccordionSummary>
      </ThemeProvider>
      <ThemeProvider theme={fontTheme}>
        <AccordionDetails className={classes.accordDetail}>
          <CalendarTable coachSchedule={coachSchedule} date={date} month={month}/>
        </AccordionDetails>
      </ThemeProvider>

    </Accordion>
  )
}