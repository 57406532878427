export const CloseIcon = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 3.71214L17.2879 2L10.5 8.78786L3.71214 2L2 3.71214L8.78786 10.5L2 17.2879L3.71214 19L10.5 12.2121L17.2879 19L19 17.2879L12.2121 10.5L19 3.71214Z"
      fill="currentColor"
    />
  </svg>
)