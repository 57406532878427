/** @format */

import React, { useState, useEffect, useMemo, useRef } from 'react';
import './index.scss';
import { parseCookies } from 'nookies';
import { auth_admin } from '../../api/auth';
import _ from 'lodash';
import { Loader } from '../../components/Loader';
import moment from 'moment';
import { useParams } from 'react-router-dom';

function checkTime(i) {
	if (i < 10) i = '0' + i;
	return i;
}

export const ScoreBoard = () => {
	const { age } = useParams();

	const [method, setMethod] = useState(undefined);
	const [params, setParams] = useState(undefined);
	const [isLoad, setIsLoad] = useState(false);
	const [error, setError] = useState(false);
	const [list, setList] = useState(undefined);

	const [tableData, setTableData] = useState(undefined);

	const [lazyload, setLazyload] = useState(false);
	const [rowsLazyloading, setRowsLazyloading] = useState(undefined);
	const [offset, setOffset] = useState(0);
	const [page, setPage] = useState(0);

	const juniorLimit = 10;
	const limit = 20;

	const apiTimer = useRef(null);
	const timer = useRef(null);
	const [currentTime, setCurrentTime] = useState(
		new Date().getHours() + ':' + new Date().getMinutes()
	);

	useEffect(() => {
		getCurrentTime();

		const cookies = parseCookies();
		init(cookies);

		if (apiTimer.current) {
			return () => {
				clearInterval(apiTimer.current);
			};
		}
	}, []);

	const preparedList = useMemo(() => {
		if (list) {
			const rows = [];

			const now = new Date();
			const later = new Date();
			later.setMinutes(later.getMinutes() + 30);

			list.data.forEach((coachGroup) => {
				coachGroup.data
					.filter((coachGroupItem) => {
						return coachGroupItem['PeriodDay'] === 'Сегодня';
					})
					.forEach((coachGroupItem) => {
						coachGroupItem.data.forEach((item) => {
							if (item.TypeWorkout !== 'Группа') return;

							const { StartTime, EndTime } = item;

							const start = new Date();
							start.setHours(+StartTime.slice(0, 2), +StartTime.slice(-2));

							const end = new Date();
							end.setHours(+EndTime.slice(0, 2), +EndTime.slice(-2));

							if ((start < now && end > now) || (later >= start && start >= now)) {
								if (age == 'junior') {
									let juniorGroup = false;
									let groupAges = item['AgeGroup']
										.replace(/[^\d\-]/g, '')
										.split('-');

									if (
										groupAges.length == 1 &&
										groupAges[0] >= 3 &&
										groupAges[0] <= 6
									) {
										juniorGroup = true;
									} else if (
										groupAges.length == 2 &&
										groupAges[0] >= 3 &&
										groupAges[1] <= 6
									) {
										juniorGroup = true;
									}

									if (!juniorGroup) return false;
								}

								rows.push({
									columns: [
										`${item['StartTime']} - ${item['EndTime']}`,
										item['Discipline'],
										coachGroup['Coach'],
										item['AgeGroup'],
										item['Level'],
										item['Location'],
										item['MeetingPlace'],
									],
								});
							}
						});
					});
			});

			rows.sort(
				(a, b) =>
					moment.utc(a.columns[0].substr(0, a.columns[0].indexOf(' -')), 'HH:mm') -
					moment.utc(b.columns[0].substr(0, b.columns[0].indexOf(' -')), 'HH:mm')
			);

			return {
				head: {
					columns: [
						'Время',
						'Дисциплина',
						'Инструктор',
						'Группа',
						'Уровень',
						'Локация',
						'Место встречи',
					],
				},
				rows: rows,
			};
		}

		return undefined;
	}, [list]);

	useEffect(() => {
		if (preparedList) {
			setTableData({
				...preparedList,
				rows: preparedList.rows.slice(0, age === 'junior' ? juniorLimit : limit),
			});
		}
	}, [preparedList]);

	useEffect(() => {
		if (preparedList) {
			timer.current = setTimeout(() => {
				setOffset((prevState) => prevState + limit);
				setLazyload(true);
			}, 1000 * 30);

			if (timer.current) {
				return () => {
					clearTimeout(timer.current);
				};
			}
		}
	}, [page, preparedList]);

	useEffect(() => {
		if (lazyload) {
			setRowsLazyloading('start');
			if (offset + limit >= preparedList.rows.length) {
				setTableData({
					...preparedList,
					rows: preparedList.rows.slice(0, limit),
				});

				setOffset(0);
			} else {
				setTableData({
					...preparedList,
					rows: preparedList.rows.slice(offset, offset + limit),
				});
			}
			setRowsLazyloading('success');
		}
	}, [lazyload]);

	useEffect(() => {
		if (rowsLazyloading === 'success') {
			setRowsLazyloading(undefined);
			setLazyload(false);
			setPage(offset);
		}
	}, [rowsLazyloading]);

	const init = (cookies) => {
		if (cookies) {
			let params = JSON.parse(cookies?.authData);

			if (params.role === 'admin') {
				authMethod(auth_admin, params);

				apiTimer.current = setInterval(
					() => {
						clearTimeout(timer.current);
						setOffset(0);
						setPage(0);

						authMethod(auth_admin, params);
					},
					5 * 60 * 1000
				);
			}
		}
	};

	const authMethod = (method, params) => {
		setIsLoad(true);
		method(params)
			.then((response) => {
				if (response.data.Success) {
					setList(response.data);
				} else {
					throw new Error(
						`The response return not success: Texterror ${response.data.TextError}`
					);
				}
				setIsLoad(false);
			})
			.catch((error) => {
				setIsLoad(false);
				setError('Ошибка сервера');
				console.error(error);
			});
	};

	function getCurrentTime() {
		const today = new Date();
		var h = today.getHours();
		var m = checkTime(today.getMinutes());
		setCurrentTime(h + ':' + m);

		setTimeout(() => {
			if (m !== checkTime(new Date().getMinutes)) {
				getCurrentTime();
			}
		}, 1000);
	}

	return (
		<div className="scoreboard">
			<div className="scoreboard__head">
				<div className="scoreboard__title">Расписание тренировок онлайн</div>
				<div className="scoreboard__time">{currentTime}</div>
			</div>
			{tableData ? (
				<table className="scoreboard-table">
					<thead className="scoreboard-table__head">
						<tr>
							{tableData.head.columns.map((col, index) => (
								<th className="scoreboard-table__head-item" key={index}>
									{col}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{tableData.rows.map((row, index) => (
							<tr key={index}>
								{row.columns.map((col, index) => (
									<td className="scoreboard-table__body-item" key={index}>
										<div style={index === 2 ? { width: '265px' } : {}}>
											{col}
										</div>
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			) : (
				<Loader />
			)}
		</div>
	);
};
