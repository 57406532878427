import React from 'react'
import './index.scss'
import { Login as LoginForm } from '../../modules/Login'
import { Header } from '../../components/Header'


export const Login = ({passedState}) => {
  return (
    <>
      <Header noMenu={true}/>
      <div className="kant-login">
        <div className="kant-login__container">
          <LoginForm passedState={passedState}/>
        </div>
      </div>
    </>

  );
}
