import React from "react";
import { TODAY } from "../../constants";
import { RecordItemAdmin } from "../../components/RecordItemAdmin";
import _, {sortBy} from "lodash";
import moment from "moment";

export const MainAdmin = ({list}) => {
  const groupedArr = {data: list?.data?.map((coach) => {
    const { Coach } = coach
    let data = _(coach.data).groupBy((period) => period.PeriodDay).value();
    const dataMap = {};
    Object.keys(data).forEach(period => dataMap[period] = sortBy((data[period]).reduce((prev, curr) => {
      return prev.concat(curr.data)
    }, []), (dataTime) => {
      const timeArr = dataTime.StartTime.split(':')
      return (moment().set({hour: timeArr[0], minute: timeArr[2]}).toISOString())
    }))

    return {
      Coach,
      data: dataMap
    }
  })}
  return (
    <div>
      {groupedArr.data && groupedArr.data.length > 0 ? groupedArr?.data?.map((el, i) => (
        <div key={i}>
          <h2 className="kant-record__subtitle">{el.Coach}</h2>
          {el.data && Object.keys(el.data).map((element, index) => {
            return (
              <div className="kant-record-card" key={`itemSchedule` + index}>
                <h2 className="kant-record__title">{element}</h2>
                {el.data[element] && el.data[element].length > 0 && el.data[element].map((el_it, idx) => {
                  return (
                  <RecordItemAdmin
                    key={`itemSchedule` + idx}
                    item={el_it}
                    isToday={element === TODAY}
                    isFirst={index === 0 && idx === 0}
                  />
                )})}
              </div>
            )
          })}
        </div>
      ))
        : <div className='emptyList'>элементов не обнаружено</div>
      }
    </div>
  )
}