import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { InputField } from "../../components/Form/InputField";
import { Button } from "../../components/Button";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { setCookie } from "nookies";

import { validate } from "../../utilities/validator";
import { auth, auth_admin, auth_ski_patrol } from "../../api/auth";

import { useLogin } from "./state";

import useRecordContext from "../../context/recordContext";
import useAuthContext from "../../context/authContext";

import "./index.scss";

const ROLES = [
  {
    label: 'Тренер',
    value: 'coach',
  },
  {
    label: 'Администратор',
    value: 'admin'
  },
  {
    label: 'Лыжный патруль',
    value: 'ski_patrol'
  }
]

export const Login = withRouter(({history}) => {
  const {recordPage, setRecordPage} = useRecordContext();
  const {setAuthData} = useAuthContext();

  const login = useLogin();

  const [error, setError] = useState('');
  const [load, setLoad] = useState(false);
  const [roleList] = useState(ROLES);
  const [person_role, setRole] = React.useState('coach');

  const {tel, password} = login;


  const [unmaskedTel, setUnmaskedTel] = useState('')

  useEffect(()=>{
    setUnmaskedTel(tel.value.replace(/[\D]+/g, '').slice(1))
  },[tel])

  const authMethod = (method, personRole) => {
    method({
      Login: unmaskedTel,
      password: password.value,
    })
      .then((response) => {
        if (response.data.Success) {
          let params = {
            Login: unmaskedTel,
            password: password.value,
            role: personRole
          }
          if (response.data.CoachID) {
            params.coachId = response.data.CoachID
          }
          console.log('params', params)
          setCookie(null, 'authData', JSON.stringify(params), {
            maxAge: 400 * 24 * 60 * 60,
            path: '/',
          });
          setAuthData(true);
          console.log('SCHEDULE', response.data)

          if (personRole === 'coach') {
            setRecordPage(response.data.schedule)
          }
          if(personRole === 'admin') {
            setRecordPage(response.data.data)
          }
          history.push('/');
        } else {
          throw new Error(`The response return not success: Texterror ${response.data.TextError}`)
        }
        setLoad(false);
      })
      .catch((error) => {
        console.error(error);
        setError('Ошибка сервера');
        setLoad(false)
      });
  };

  useEffect(() => {
    console.log('RECORD1', recordPage)
  }, [recordPage])

  const sendForm = (role) => {
    if (!role || role === '') {
      setError('Данные введены не корректно')
      setTimeout(() => {
        setLoad(false)
      }, 2000);
    }
    setLoad(true)
    if (validate(login)) {
      switch (role) {
        case "admin":
          authMethod(auth_admin, role)
          break
        case "coach":
          authMethod(auth, role)
          break
        case "ski_patrol":
          authMethod(auth_ski_patrol, role)
          break
      }
    } else {
      setError('Данные введены не корректно')
      setTimeout(() => {
        setLoad(false)
      }, 2000);
    }
  };

  return (
    <form
      className="kant-form"
      onSubmit={(event) => event.preventDefault()}
    >
      {error && <div className="kant-form__error">{error}</div>}
      <InputField type="tel" {...tel.bind} />
      <InputField type="password" {...password.bind} />
      <RadioGroup
        aria-label="person_role"
        name="person_role"
        value={person_role}
        onChange={(e) => setRole(e.target.value)}
        className="formRadio"
      >
        {roleList.map((role, index) => (
          <FormControlLabel
            key={index}
            value={role.value}
            control={<Radio color="primary"/>}
            label={role.label}
          />
        ))}
      </RadioGroup>
      <div className="kant-form__submit">
        <Button isLoad={load} title="Войти" onClick={() => sendForm(person_role)}/>
      </div>
    </form>
  );
});
