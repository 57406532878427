import { API_URL } from "../constants";
import axios from "axios";

export async function getLocationEventList(params) {
  const response = await axios.post(`${API_URL}/SprLocationEvent`, params);
  const data = await response;

  return data.data;
}

export async function setLocationEvent(params) {
  const response = await axios.post(`${API_URL}/ChangeTrainingLocation`, params);
  const data = await response;

  return data.data;
}
