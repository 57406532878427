import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  bread: {
    padding: '20px 30px 10px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '10px',
    color: '#0b509c',
    cursor: 'pointer',
    backgroundColor: '#efefef',
    fontWeight: 600,
  }
}))