import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Redirect, Route } from 'react-router'
import { Header } from '../Header'

export const PrivateRoute = ({component: Component, auth, noHeader = false, ...rest}) => {
  const [authData, setAuthData] = useState()

  useLayoutEffect(() => {
    if (auth) {
      setAuthData(JSON.parse(auth))
    }

  }, [auth])

  return (
    <Route
      {...rest}
      render={(props) =>
        auth
          ? <>
            {!noHeader && <Header role={authData?.role}/>}
            <Component userId={authData?.coachId} {...props} {...rest}/>
          </>
          : <Redirect to="/login"/>
      }
    />
  );
};