import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHeader: {
    height: '30px',
    padding: 0,
    borderTopWidth: '0 !important',
  },
  tableRow: {
    backgroundColor: '#0b509c',
    color: '#ffffff',
    fontSize: '14px',
    textAlign: 'center'
  },
  tableBorder: {
    borderWidth: 0.5,
    borderColor: 'black',
    borderStyle: 'solid',
    width: '106px',
    minWidth: '106px',
  },
  tableCell: {
    padding: '4px 0',
    textAlign: 'center',
    verticalAlign: 'top',
  },
  actionTime: {
    fontSize: '11px',
    fontWeight: '700'
  },
  action: {
    fontSize: '11px',
    display: 'flex',
    flexDirection: 'column',
  },
});