export const API_URL = process.env.REACT_APP_API_URL || "/kant_school_ws/hs/lkKantSport/v1/CoachSchedule"
// export const API_URL = "/kant_school_ws/hs/lkKantSport/v1/CoachSchedule"
export const API_URL2 = process.env.REACT_APP_API_URL2
export const API_URL3 = process.env.REACT_APP_API_URL3

// 'https://trener.kant.ru/api'
// '/kant_school_test_ws/hs/lkKantSport/v1/CoachSchedule'
// 'https://1c1.kant.ru/kant_school_ws/hs/lkKantSport/v1/CoachSchedule'
// '/kant_school_ws/hs/lkKantSport/v1/CoachSchedule'
// '/kant_school_test_ws/hs/lkKantSport/v1/CoachSchedule';

export const CHECKED = 'Отмечен';
export const TOMMOROW = 'Завтра';
export const TODAY = 'Сегодня';
export const WEEKDAYS = [
  'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'
]

// console.log(process.env)


