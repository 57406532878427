import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  drawer: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#000000'
  },

  closeButton: {
    paddingRight: 0,

    '& svg': {
      color: '#ffffff'
    }
  },

  list: {
    flexGrow: 1,
    marginTop: 50,
  },

  listItem: {
    display: 'flex',
    justifyContent: 'center',
    color: '#ffffff',
  }
}))