import React, { useEffect, useState } from "react";
import { RecordItemSki } from "../../components/RecordItemSki";
import {groupBy, sortBy } from "lodash";
import moment from "moment";
import { WEEKDAYS } from "../../constants";

export const MainSkiPatrol = ({list}) => {
  const [days, setDays] = useState([])

  useEffect(() => {
    const groupedArr = groupBy(sortBy(list?.data, (item) => moment(item.TimeStart)), (item) => moment(item.TimeStart).startOf('day').format(), 'DD/MM/YYYY')
    setDays(groupedArr)
  }, [list])

  return (
    <>
      {
        Object.keys(days).map((day, i) => (
        <div key={`itemDay${i}`}>
          <h2 className="kant-record__title">{WEEKDAYS[moment(day).day() - 1]}</h2>
          {days[day].map((item, j) => (
            <div key={`itemSchedule` + j}>
              <RecordItemSki
                item={item}
                isToday={moment(day).isSame(moment(), 'd')}
                isFirst={j === 0}
              />
            </div>
          ))}
        </div>
        ))
      }
    </>
  )

}