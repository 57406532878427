import React, { useEffect, useState } from "react";
import cn from "classnames";

export const RecordItemSki = ({item, isToday, isFirst}) => {
  const [isDetail, setIsDetail] = useState(false)
  const tmStart = new Date(item.TimeStart)
  const tmEnd = new Date(item.TimeEnd)

  const fullTimeStart = tmStart.getHours() + ":" + (tmStart.getMinutes() < 10 ? '0' : '') + tmStart.getMinutes()
  const fullTimeEnd = tmEnd.getHours() + ":" + (tmEnd.getMinutes() < 10 ? '0' : '') + tmEnd.getMinutes()


  useEffect(() => {
    if (isFirst) {
      setIsDetail(true)
    }
  }, [])

  return (
    <>
      {!isDetail
        ? (
          <div
            className={cn("kant-record-item", {"kant-record-item__tommorow": !isToday})}
            onClick={() => setIsDetail(!isDetail)}
          >
            <div className="kant-record-item__description">
              <p className="kant-record-item__period">
                {fullTimeStart} - {fullTimeEnd}
              </p>
              <p className="kant-record-item__name">{item.Discipline} ({item.GroupView})</p>
            </div>
          </div>
        ) : (
          <SkiItemDetail
            item={item}
            isToday={isToday}
            click={() => setIsDetail(!isDetail)}
            timeStart={fullTimeStart}
            timeFinish={fullTimeEnd}
          />
        )}
    </>
  )
}

export const SkiItemDetail = ({item, click, isToday, timeFinish, timeStart}) => {
  return (
    <div className="kant-record-item__detail">
      <div className="kant-detail__header" onClick={() => click()}>
        <div className="kant-detail__header-wrapper">
          <div className="kant-record-item__description-detail">
            <p className="kant-record-item__period-detail">{timeStart} - {timeFinish}</p>
            <p className="kant-record-item__name-detail">{item.Discipline} ({item.GroupView})</p>
          </div>
        </div>
        <p className="kant-record-item__name kant-indent--bottom">Место катания (коридор): {item.Location}</p>
        <p className="kant-record-item__name kant-indent--bottom">Тренер: {item.Coach}</p>
        <p className="kant-record-item__name kant-indent--bottom">Количество отмеченных: {item.Marked}</p>
      </div>
    </div>
  )
}