import axios from 'axios'
import { API_URL2 } from '../constants'

const token = 'N49odMAd03K9frcQvZoEPihK5KQNH2Ib'

export const getCoaches = async () => {
  const result = await axios.get(`${API_URL2}/schedule/trainers/?token=${token}`)

  return result.data
}

export const getCoach = async (id) => {
  const result = await axios.get(`${API_URL2}/schedule/trainers/${id}/?token=${token}`)

  return result.data
}

export const setCoach = async (data) => {
  const result = await axios.post(`${API_URL2}/schedule/trainers/?token=${token}`, data)
}