import React from 'react';
import cn from 'classnames';
import Select from 'react-select';
import { setLocationEvent } from '../../api/location-event';
import { setMeetingPlace } from '../../api/meeting-place';
import { CHECKED } from '../../constants';

import './index.scss';

export const RecordItemDetail = ({ item, click, locationList, meetingPlaceList }) => {
	const selectLocation = (option) => {
		setLocationEvent({
			IdTraining: item.IdWorkout,
			IdTrainingLocation: option.value,
		});
	};

	const selectMeetingPlace = (option) => {
		setMeetingPlace({
			IdTraining: item.IdWorkout,
			IdLocation: option.value,
		});
	};

	const getLocationOptions = (items) =>
		items?.map((item) => ({
			value: item.UidLocation,
			label: item.Location,
		}));

	const getMeetingPlaceOptions = (items) =>
		items?.map((item) => ({
			value: item.UidLocation,
			label: item.Location,
		}));

	const getLocationValue = (items) => {
		const currentItem = items?.find((el) => el.Location === item.Location);

		return currentItem
			? {
					value: currentItem.UidLocation,
					label: currentItem.Location,
			  }
			: null;
	};

	const getMeetingPlaceValue = (items) => {
		const currentItem = items?.find((el) => el.Location === item.MeetingPlace);

		return currentItem
			? {
					value: currentItem.UidLocation,
					label: currentItem.Location,
			  }
			: null;
	};

	const checkStateClient = (clients) => {
		const result = clients?.filter((el) => el.State === CHECKED);
		return result?.length;
	};

	return (
		<div className="kant-record-item__detail">
			<div className="kant-detail__header" onClick={() => click()}>
				<div className="kant-detail__header-wrapper">
					<div className="kant-record-item__description-detail">
						<p className="kant-record-item__period-detail">
							{item.StartTime} - {item.EndTime}
						</p>
						<p className="kant-record-item__name-detail">
							{item.Discipline} ({item.TypeWorkout})
						</p>
					</div>
					<p>
						{item.Clients?.length}/{checkStateClient(item.Clients)}
					</p>
				</div>
				<p className="kant-record-item__name kant-indent--bottom">
					Возраст: {item.AgeGroup}
				</p>
				<p className="kant-record-item__name">Уровень: {item.Level}</p>
			</div>
			{locationList && (
				<div className="kant-detail__header-detail">
					<div className="kant-detail__control">
						<div className="kant-detail__control-label">Место катания:</div>
						<div className="kant-detail__control-select">
							<Select
								defaultValue={getLocationValue(locationList)}
								options={getLocationOptions(locationList)}
								onChange={selectLocation}
								placeholder="Выберите..."
							/>
						</div>
					</div>
					<div className="kant-detail__control">
						<div className="kant-detail__control-label">Место встречи:</div>
						<div className="kant-detail__control-select">
							<Select
								defaultValue={getMeetingPlaceValue(meetingPlaceList)}
								options={getMeetingPlaceOptions(meetingPlaceList)}
								onChange={selectMeetingPlace}
								placeholder="Выберите..."
							/>
						</div>
					</div>
				</div>
			)}
			{item?.Clients?.map((el, i) => {
				return (
					<div key={'detail' + i} className="kant-detail__customer">
						<div className="kant-detail__customer-text kant-indent--right">
							<p className="kant-detail__customer-text-name kant-indent--bottom">
								{el.FIO}
							</p>
							<p className="kant-detail__customer-text-phone">
								<a href={`tel:${el.Telephone}`}>{el.Telephone}</a>
							</p>
						</div>
						<div
							className={cn('kant-detail__mark kant-detail__yellow', {
								'kant-detail__green': el.State === CHECKED,
							})}
						>
							{el.State}
						</div>
					</div>
				);
			})}
		</div>
	);
};
