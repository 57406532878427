import axios from 'axios'
import { API_URL2 } from '../constants'

const token = 'N49odMAd03K9frcQvZoEPihK5KQNH2Ib'

const returnFormData = (data) => {
  console.log('ZZZ', data)
  const formData = new FormData()

  for (const key in data) {
    console.log(key, data[key])
    formData.append(key, data[key])
  }
  formData.append('token', token)

  return formData
}

export const sendSchedule = async (data) => {
  // const formData = returnFormData(data)

  return await axios.post(`${API_URL2}/schedule/?token=${token}`, data)
}

export const processSchedule = async (data) => {
  const result = await axios.post(`${API_URL2}/schedule/processed/?token=${token}`, data)

  return result.data
}

export const getSchedules = async (id) => {
  const result = await axios.get(`${API_URL2}/schedule/?${id}&token=${token}`)

  return result.data
}

export const getSchedule = async (id) => {
  const result = await axios.get(`${API_URL2}/schedule/${id}/?token=${token}`)

  return result.data
}

export const getCoachFio = async (id) => {
  const result = axios.get(`${API_URL2}/schedule/trainers/${id}/?token=${token}`)

  return result;
}