import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core";

export const AccordSummarytheme = createMuiTheme({
  overrides: {
    MuiAccordionSummary: {
      content: {
        flexGrow: 0,
      }
    },
    MuiIconButton: {
      root: {
        padding: 0,
      }
    }
  },
  typography: {
    fontFamily: ['DrunkTextWide', 'sans-serif']
  }
});

export const fontTheme = createMuiTheme({
  typography: {
    fontFamily: ['DrunkTextWide', 'sans-serif']
  }
});

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#efefef',
    boxShadow: 'none',
    margin: '0 !important',

    "&::before": {
      background: 'none'
    }
  },
  heading: {
    fontSize: '14px',
    fontWeight: '700',
  },
  accordDetail: {
    padding: 0,
  },
  accordHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    textTransform: 'uppercase',
    height: '33px !important',
    minHeight: '33px !important',
    padding: '0 30px',
    fontFamily: 'inherit',

    "&-content": {
      flexGrow: 0,
    }
  },
  accordIcon: {
    padding: 0,
    color: '#161616',
  },
}))