export const NoticeIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00008 0.666672C4.40008 0.666672 0.666748 4.40001 0.666748 9.00001C0.666748 13.6 4.40008 17.3333 9.00008 17.3333C13.6001 17.3333 17.3334 13.6 17.3334 9.00001C17.3334 4.40001 13.6001 0.666672 9.00008 0.666672ZM9.83342 13.1667H8.16675V8.16667H9.83342V13.1667ZM9.83342 6.50001H8.16675V4.83334H9.83342V6.50001Z"
      fill="#2D3C70"
    />
  </svg>
)