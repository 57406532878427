import React, { useEffect, useState } from 'react'
import { parseCookies } from 'nookies'
import { BrowserRouter } from 'react-router-dom'
import { Redirect, Route, Switch } from 'react-router'
import useAuthContext from './context/authContext'
import { Login } from './pages/Login/'
import { Main } from './pages/Main/'
import { Header } from './components/Header'
import { PrivateRoute } from './components/PrivateRoute'
import { Calendar } from './pages/Calendar'
import { SetSchedule } from './components/SetSchedule'
import { CoachRequests } from './components/CoachRequests'
import { CoachRequestDetail } from './components/CoachRequestDetail'
import { ScoreBoard } from './pages/ScoreBoard'

export const App = () => {
  const cookies = parseCookies();
  const [init, setInit] = useState(false);
  const {authData = null, setAuthData} = useAuthContext();

  useEffect(() => {
    setAuthData(cookies?.authData);
    setInit(true);
  }, [authData]);

  if (!init) return null;
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute exact auth={authData} path="/" component={Main}/>
        <PrivateRoute exact auth={authData} path="/calendar" component={Calendar}/>
        <PrivateRoute exact auth={authData} path="/login_admin" component={Calendar}/>
        <PrivateRoute exact auth={authData} path="/coach_schedule" component={SetSchedule}/>
        <PrivateRoute exact auth={authData} path="/coach_requests" component={CoachRequests}/>
        <PrivateRoute exact auth={authData} path="/coach_requests/:id" component={CoachRequestDetail} noHeader={true}/>
        <PrivateRoute exact auth={authData} path="/ski_patrol" component={Calendar}/>
        <PrivateRoute exact auth={authData} path="/scoreboard/:age?" component={ScoreBoard}/>

        {!authData && <Route strict path="/login" component={Login}/>}
        <Redirect to={"/"}/>
      </Switch>
    </BrowserRouter>
  );
}

export default App


