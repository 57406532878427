import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import classNames from "classnames";
import { fontTheme } from "../CalendarAccordion/style";
import { ThemeProvider, Typography } from "@material-ui/core";
import { useStyles } from "./style";
import useRecordContext from "../../context/recordContext";

const days = [
  {
    dayShortName: 'Пн',
    dayFullName: 'Понедельник',
    dayIndex: 0
  },
  {
    dayShortName: 'Вт',
    dayFullName: 'Вторник',
    dayIndex: 1
  },
  {
    dayShortName: 'Ср',
    dayFullName: 'Среда',
    dayIndex: 2
  },
  {
    dayShortName: 'Чт',
    dayFullName: 'Четверг',
    dayIndex: 3
  },
  {
    dayShortName: 'Пт',
    dayFullName: 'Пятница',
    dayIndex: 4
  },
  {
    dayShortName: 'Сб',
    dayFullName: 'Суббота',
    dayIndex: 5
  },
  {
    dayShortName: 'Вс',
    dayFullName: 'Воскресенье',
    dayIndex: 6
  }
]


export const CalendarTable = ({coachSchedule = [], month, monthNum}) => {
  const [daysMonth, setDays] = useState([])
  const {recordPage = null} = useRecordContext();

  const date = new Date();
  const year = date.getFullYear()
  let firstDay = new Date(year, month.monthNum, 1).getDay() - 1;
  if (firstDay < 0) {
    firstDay = 6
  }

  const classes = useStyles();
  useEffect(() => {
    let newMonth = []
    let j = firstDay
    for (let i = 0; i < month.monthDaysNum; ++i, ++j) {
      if (j > 6) { //для повторений дней недели
        j = 0
      }
      const firstDayOfMonth = days.find(day => day.dayIndex === j)
      if (firstDayOfMonth) {
        const newDay = {
          dayShortName: firstDayOfMonth.dayShortName,
          dayFullName: firstDayOfMonth.dayFullName,
        }
        newMonth.push(newDay)
      }
    }
    setDays(newMonth)
  }, [coachSchedule, month])


  const tableDaysTitle = daysMonth.map((day, index) => (
    <TableCell key={index} className={classNames(classes.tableRow, classes.tableBorder, classes.tableHeader)}>
      {index + 1} {day.dayShortName}
    </TableCell>
  ))

  const tableDaysAction = (monthSchedule) => {
    const row = monthSchedule.map((action, index) => (
      <TableCell
        key={index}
        className={classNames(classes.tableCell, classes.tableBorder)}
        component="th"
      >
        <ThemeProvider theme={fontTheme}>
          {action.content.startTime !== " "
            ? <Typography className={classes.actionTime}>
                {action.content.startTime}-{action.content.endTime}
              </Typography>
            : null
          }
        </ThemeProvider>
        <div className={classes.action}>
          <p>{action?.action}</p>
          <p>{action?.actionType}</p>
          <p>{action?.ageGroup}</p>
          <p>{action?.level}</p>
        </div>
      </TableCell>
    ))
    return row
  }

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="train table">
        <TableHead>
          <TableRow>
            {tableDaysTitle}
          </TableRow>
        </TableHead>
        <TableBody>
          {coachSchedule?.days.map((monthSchedule, index) => (
            <TableRow key={index} component="tr">
              {tableDaysAction(monthSchedule)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}