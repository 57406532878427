import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Controller, useForm } from 'react-hook-form'
import { Button, ClickAwayListener, IconButton, TextField, Tooltip } from '@material-ui/core'
import classNames from 'classnames'
import { NoticeIcon } from '../icons/NoticeIcon'
import { CloseIcon } from '../icons/CloseIcon'
import { sendSchedule, getCoachFio } from '../../api/schedule'
import { setCoach } from '../../api/coaches'
import { useHistory } from 'react-router'

import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

const days = [
  {title: 'Понедельник', name: 'monday'},
  {title: 'Вторник', name: 'tuesday'},
  {title: 'Среда', name: 'wednesday'},
  {title: 'Четверг', name: 'thursday'},
  {title: 'Пятница', name: 'friday'},
  {title: 'Суббота', name: 'saturday'},
  {title: 'Воскресенье', name: 'sunday'},
]

const TooltipInside = ({setOpenTool, type}) => {

  return (
    <div className='tooltip'>
      <IconButton onClick={() => setOpenTool(false)}><CloseIcon/></IconButton>

      {type === 'group'
        ? <>
          <div className='notice'>Укажите время тренировки в&nbsp;диапазоне от&nbsp;08:00 до&nbsp;23:00&nbsp;с
            интервалом в&nbsp;пол часа.
          </div>
          <span>Примечание: <br/>Если в один день вы планируете открывать две и более тренировок, подайте заявку повторно с другим временем тренировки. Оставьте поле пустым, если не планируете работать в этот день</span>
        </>
        : <>
          <div className='notice'>Укажите время тренировки в диапазоне от 08:00 до 23:00.</div>
          <span>Примечание: <br/>Если в один день вы планируете открывать два временных промежутка подайте заявку повторно с другим временем тренировки. Оставьте поле пустым, если не планируете работать в этот день</span>
        </>
      }
    </div>
  )
}

export const SetSchedule = ({userId}) => {
  const history = useHistory()
  const initialValues = {
    name: '',
    type: {value: '', label: ''},
    period: '',
    description: '',
    monday: '',
    tuesday: '',
    wednesday: '',
    thursday: '',
    friday: '',
    saturday: '',
    sunday: '',
    comment: ''
  }

  const [openTool, setOpenTool] = useState(false)
  const {control, handleSubmit, watch, register, reset, getValues} = useForm()
  const [openForm, setOpenForm] = useState(false)

  const handleTooltipClose = () => {
    setOpenTool(false)
  }
  const handleTooltipOpen = (e) => {
    e.preventDefault()
    setOpenTool(true)
  }

  const onSubmit = async (data) => {
    if (data) {
      console.log(data)

      const newData = {
        name: data.name,
        type: data.type.value,
        period: data.period,
        description: data.description,
        days: {
          monday: data.monday,
          tuesday: data.tuesday,
          wednesday: data.wednesday,
          thursday: data.thursday,
          friday: data.friday,
          saturday: data.saturday,
          sunday: data.sunday,
        },
        comment: data.comment,
        processed: false,
        coachId: userId ?? 'id not found'
      }
      console.log('newData', newData)
      const res_coach = await setCoach({name: newData.name, id: userId})
      if (res_coach) {
        console.log(res_coach)
      }

      const res = await sendSchedule(newData)
      if (res.data.success) {
        console.log('SSSS', res.data.success)
        toast.success("Расписание добавлено", {
          position: toast.POSITION.BOTTOM_CENTER
        })

        history.push('/')
      } else {
        console.log(res)
        toast.error(res.error, {
          position: toast.POSITION.TOP_CENTER
        })
      }
    }
  }

  useEffect(() => {
    if (watch('type')) {
      console.log(watch('type').value)
      setOpenForm(true)
    }
  }, [watch('type')])

  useEffect(() => {
    if (userId) {
      getCoachFio(userId)
        .then(response => {
          if (response.data.success) {
            const name = response.data.data['UF_COACH_FIO']
            
            if (!name) return;

            reset({ name });
          }
        })
        .catch(e => {
          console.errror(e);
        })
    }
  }, [userId]);

  return (
    <main className="kant-main">
      <h2 className="kant-record__title kant-record__title_medium">Подать расписание</h2>

      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className='admin_shedule_form'
      >
        <TextField
          {...register('name')}
          className='form_input'
          fullWidth
          placeholder={'Укажите ФИО'}
          variant="outlined"
          style={{marginBottom: 20}}
          inputProps={
            { readOnly: !!getValues('name')}
          }
        />

        <Controller
          {...register('type')}
          control={control}

          render={({field}) => (
            <Select
              {...field}
              className="schedule-select"
              placeholder={<div className="form_select-placeholder">Выберите тип тренировки</div>}
              styles={{singleValue: () => ({color: '#808080'})}}
              options={[
                {value: "group", label: "Групповая тренировка"},
                {value: "individual", label: "Индивидуальная тренировка"},
              ]}
            />
          )}
        />

        {openForm && <>
          <TextField
            {...register('period')}
            className='form_input'
            fullWidth
            placeholder='Укажите период'
            variant="outlined"
            style={{marginTop: 20}}
          />

          <TextField
            {...register('description')}
            className='form_input'
            fullWidth
            placeholder={watch('type')?.value === 'group' ? 'Укажите группу' : 'Укажите тип обучения'}
            variant="outlined"
            style={{marginTop: 20}}
          />

          <h2
            className="kant-record__title kant-record__title_medium"
            style={{marginTop: 40}}
          >
            Укажите время
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                open={openTool}
                type={watch('type')?.value}
                title={<TooltipInside setOpenTool={setOpenTool}/>}
              >
                <IconButton
                  onClick={handleTooltipOpen}
                  style={{padding: '8px 0 12px 8px'}}
                >
                  <NoticeIcon/>
                </IconButton>
              </Tooltip>
            </ClickAwayListener>
          </h2>


          {days.map((day, i) =>
            <div key={i} className='schedule_day'>
              <div style={{color: '#808080'}}>{day.title}</div>
              <TextField
                {...register(day.name)}
                className='form_input'
                placeholder='Укажите время'
                variant="outlined"
              />
            </div>
          )}

          <TextField
            {...register('comment')}
            fullWidth
            className={classNames('form_input', 'form_textarea')}
            placeholder='Ваш комментарий...'
            variant="outlined"
            multiline
            rows={5}
          />

          <div className='form_button'>
            <Button type='submit'>
              <p>Подать расписание</p>
            </Button>
          </div>
        </>}
      </form>
      {/*<ToastContainer limit={3}/>*/}
    </main>
  )
}

