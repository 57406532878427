import React, { useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'
import { PlusIcon } from '../icons/PlusIcon'
import './index.scss'
import { useHistory } from 'react-router'
import { getCoaches } from '../../api/coaches'
import { getSchedules } from '../../api/schedule'

const coachesData = {
  newRequests: [
    {
      name: 'Шайбин Сергей Ревленович',
      training: [
        {name: 'Групповая тренировка'},
        {name: 'Индивидуальная тренировка'},
        {name: 'Индивидуальная тренировка'}
      ]
    },
    {
      name: 'Капетий Виктор Павлович',
      training: [
        {name: 'Групповая тренировка'},
        {name: 'Индивидуальная тренировка'},
        {name: 'Индивидуальная тренировка'}
      ]
    }
  ],
  doneRequests: [
    {
      name: 'Шайбин Сергей Ревленович',
      training: [
        {name: 'Групповая тренировка'},
        {name: 'Индивидуальная тренировка'},
        {name: 'Индивидуальная тренировка'}
      ]
    },
    {
      name: 'Капетий Виктор Павлович',
      training: [
        {name: 'Групповая тренировка'},
        {name: 'Индивидуальная тренировка'},
        {name: 'Индивидуальная тренировка'}
      ]
    },
    {
      name: 'Бражников Михаил Вадимович',
      training: [
        {name: 'Групповая тренировка'},
        {name: 'Индивидуальная тренировка'},
        {name: 'Индивидуальная тренировка'}
      ]
    },
    {
      name: 'Кочнев Никита Дмитриевич',
      training: [
        {name: 'Групповая тренировка'},
        {name: 'Индивидуальная тренировка'},
        {name: 'Индивидуальная тренировка'}
      ]
    },
  ]
}

const RequestList = ({coach}) => {
  console.log(coach)
  const history = useHistory()
  const [openSchedule, setOpenSchedule] = useState(false)
  // const [coachSchedules, setCoachSchedules] = useState([])

  // useEffect(() => {
  //   if (openSchedule) {
  //     let res
  //     (async () => {
  //       res = await getSchedules({id: coach.id, status: 'new'})
  //
  //       if (res.success) {
  //         console.log('schedules', res)
  //         console.log(res)
  //         setCoachSchedules(res.data)
  //       }
  //     })()
  //   }
  // }, [openSchedule])

  return (
    <Accordion
      className='coach_list-item'
      expanded={openSchedule}
      onChange={() => setOpenSchedule(!openSchedule)}
    >
      <AccordionSummary
        expandIcon={<PlusIcon/>}
        className='inner_list-item'
      >
        <Typography>{coach?.UF_COACH_FIO}</Typography>
      </AccordionSummary>
      <AccordionDetails className='coach_details'>
        {coach?.SCHEDULES.map((train, i) =>
          <Typography
            key={i}
            onClick={() => {
              history.push('/coach_requests/' + train.ID)
            }}
          >
            {train?.UF_DESCRIPTION === '' ? 'Тип занятие не указан' : train?.UF_DESCRIPTION}
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export const CoachRequests = () => {
  const [coaches, setCoaches] = useState([])

  useEffect(() => {
    (async () => {
      const res = await getCoaches()

      console.log(res);
      if (res.success) {
        console.log('coaches', res)
        setCoaches(res.data)
      }
    })()
  }, [])

  useEffect(() => {
    if (coaches && coaches.length > 0) {
      console.log('coachesZZZ', coaches)
    }
  }, [coaches])

  // useEffect(() => {
  //   if (coaches && coaches.length > 0) {
  //     (async () => {
  //       const res = await getSchedules()
  //       if (res) {
  //         console.log('schedules', res)
  //         console.log(res)
  //       }
  //     })()
  //   }
  // }, [coaches])

  return (
    <main className="kant-main">
      <h2 className="kant-record__title kant-record__title_medium">НОВЫЕ ЗАЯВКИ</h2>
      <div className='coach_list'>
        {coaches?.new?.map((coach, index) =>
          <div key={index}>
            <RequestList coach={coach} key={index} isNew={true}/>
          </div>
        )}
      </div>
      <h2 className="kant-record__title kant-record__title_medium">ОБРАБОТАННЫЕ ЗАЯВКИ</h2>
      <div className='coach_list'>
        {coaches?.done?.map((coach, index) =>
          <div key={index}>
            <RequestList coach={coach} key={index} isDone={true}/>
          </div>
        )}
      </div>

    </main>
  )
}

