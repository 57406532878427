import React from "react";
import { useStyles } from "./style";

export const Breadcrumbs = () => {
  const classes = useStyles()
  return (
    <div className={classes.bread}>
      <a href="/">{'< Назад'}</a>
    </div>
  )
}