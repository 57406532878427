import React, { useEffect, useState } from "react";
import { CalendarAccordion } from "../../components/CalendarAccordion";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import useRecordContext from "../../context/recordContext";
import { auth } from "../../api/auth";
import { parseCookies } from "nookies";


const months = [
  {
    month: 'Январь',
    monthNum: 0,
    monthDaysNum: 31
  },
  {
    month: 'Февраль',
    monthNum: 1,
    monthDaysNum: 28
  },
  {
    month: 'Март',
    monthNum: 2,
    monthDaysNum: 31
  },
  {
    month: 'Апрель',
    monthNum: 3,
    monthDaysNum: 30
  },
  {
    month: 'Май',
    monthNum: 4,
    monthDaysNum: 31
  },
  {
    month: 'Июнь',
    monthNum: 5,
    monthDaysNum: 30
  },
  {
    month: 'Июль',
    monthNum: 6,
    monthDaysNum: 31
  },
  {
    month: 'Август',
    monthNum: 7,
    monthDaysNum: 31
  },
  {
    month: 'Сентябрь',
    monthNum: 8,
    monthDaysNum: 30
  },
  {
    month: 'Октябрь',
    monthNum: 9,
    monthDaysNum: 31
  },
  {
    month: 'Ноябрь',
    monthNum: 10,
    monthDaysNum: 30
  },
  {
    month: 'Декабрь',
    monthNum: 11,
    monthDaysNum: 31
  },
]
const days = [
  {
    dayShortName: 'Пн',
    dayFullName: 'Понедельник',
    dayIndex: 0
  },
  {
    dayShortName: 'Вт',
    dayFullName: 'Вторник',
    dayIndex: 1
  },
  {
    dayShortName: 'Ср',
    dayFullName: 'Среда',
    dayIndex: 2
  },
  {
    dayShortName: 'Чт',
    dayFullName: 'Четверг',
    dayIndex: 3
  },
  {
    dayShortName: 'Пт',
    dayFullName: 'Пятница',
    dayIndex: 4
  },
  {
    dayShortName: 'Сб',
    dayFullName: 'Суббота',
    dayIndex: 5
  },
  {
    dayShortName: 'Вс',
    dayFullName: 'Воскресенье',
    dayIndex: 6
  }
]
let monthsInfo = []
let emptyCalendarMonths = []
let calendarMonths = []

export const setCalendar_vol0 = (schedule) => {
  if (schedule && schedule.length) {
    schedule.forEach(sheduleDay => {
      const currMonth = new Date(sheduleDay.PeriodDay).getMonth() //месяц
      const currDate = new Date(sheduleDay.PeriodDay).getDate() //день
      const foundMonth = monthsInfo.find(month => month.monthNum === currMonth)
      if (foundMonth) {
        if (foundMonth.maxLenght < sheduleDay.data.length) {
          foundMonth.maxLenght = sheduleDay.data.length
        }
      } else {
        const newMonth = {
          maxLenght: sheduleDay.data.length,
          monthNum: currMonth,
        }
        monthsInfo.push(newMonth)
      }
    })
  }
}

//формирование пустых месяцев, где каждое событие имеет адрес
export const setEmptyCalendar = (monthsInfo) => {
  months.forEach(month => {
    monthsInfo.forEach(monthInfo => {
      if (month.monthNum === monthInfo.monthNum) {
        const newMonth = {
          monthNum: month.monthNum,
          days: []
        }
        for (let i = 0; i < monthInfo.maxLenght; ++i) {
          const newMonthLine = []
          for (let j = 0; j < month.monthDaysNum; ++j) {
            const newDay = {
              row: i,
              column: j,
              content: {}
            }
            newMonthLine.push(newDay)
          }
          newMonth.days.push(newMonthLine)
        }
        calendarMonths.push(newMonth)
      }
    })
  })
  emptyCalendarMonths = calendarMonths
}

export const setDataToMonths = (schedule) => {
  calendarMonths.forEach(month => {
    month.days.forEach(day => {
      day.forEach(dayAction => {
        schedule.forEach(sheduleDay => {
          const currMonth = new Date(sheduleDay.PeriodDay).getMonth() //месяц
          const currDate = new Date(sheduleDay.PeriodDay).getDate()
          if (currMonth === month.monthNum) {
            sheduleDay.data.forEach((sheduleAction, index) => {
              if (dayAction.column === currDate && dayAction.row === index) {
                dayAction.content = {
                  startTime: sheduleAction.StartTime,
                  endTime: sheduleAction.EndTime
                }
              }
            })
          }
        })
      })
    })
  })
}


export const Calendar = () => {
  const date = new Date();

  const {recordPage, setRecordPage} = useRecordContext();
  const [list, setList] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setIsLoad(true);
    const cookies = parseCookies();
    if (!recordPage) {
      init(cookies)
    } else {
      if (recordPage.length) {
        setList(recordPage)
        setIsLoad(false)

        setCalendar_vol0(recordPage)
        setEmptyCalendar(monthsInfo)
        setDataToMonths(recordPage)

      } else {
        setIsLoad(true)
      }
    }
  }, [recordPage]);

  const init = (cookies) => {
    console.log('INIT')
    let params = JSON.parse(cookies?.authData)
    auth(params)
      .then((response) => {
        if (response.data.Success) {

          setRecordPage(response.data.schedule) //ответ с сервера
          setList(response.data.schedule)

          //setRecordPage(coachData.schedule) //тестовые данные
          //setList(coachData.schedule)

        } else {
          throw new Error(`The response return not success: Texterror ${response.data.TextError}`)
        }
        setIsLoad(false);
      })
      .catch((error) => {
        setIsLoad(false);
        setError('Ошибка сервера');
        console.error(error);
      });
  }


  const calendar = months.map((month, index) => {
    const currMonth = calendarMonths?.find(currMonth => currMonth.monthNum === index)
    if (currMonth && currMonth.length !== 0) {
      return (
        <CalendarAccordion
          key={index}
          children={month}
          coachSchedule={currMonth}
          date={date}
          month={month}
        />
      )
    } else {
      return (
        <CalendarAccordion
          key={index}
          children={month}
          date={date}
          month={month}
        />
      )
    }
  })

  return (
    <div>
      <Breadcrumbs/>
      {calendarMonths.length ? calendar : <div>Loading...</div>}
    </div>
  )
}







