import { IconButton, List, ListItem, SwipeableDrawer, Typography } from '@material-ui/core'
import { Link, NavLink } from 'react-router-dom'
import logo from '../../images/logo.png'
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CloseIcon } from '../icons/CloseIcon'
import { useStyles } from './styles'

const adminLinks = [
  {
    name: 'Расписание',
    link: '/'
  },
  {
    name: 'Заявки от тренеров',
    link: '/coach_requests'
  }
]

const coachLinks = [
  {
    name: 'Расписание',
    // link: '/calendar' была задача переделать путь), страница календарь сломана из-за форматов

    link: '/'
  },
  {
    name: 'Подать расписание',
    link: '/coach_schedule'
  }
]

export const HeaderDrawer = ({open, toggleDrawer, role}) => {
  const [links, setLinks] = useState([])
  const classes = useStyles()
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

  useEffect(() => {
    setLinks(role === 'coach' ? coachLinks : adminLinks)
  }, [role])

  return (
    <SwipeableDrawer
      anchor="top"
      open={open}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
    >
      <div className={classes.drawer}>
        <div className="kant-header">
          <Link to={'/'} className="kant-logo">
            <img src={logo}/>
          </Link>
          <IconButton
            className={classes.closeButton}
            onClick={() => toggleDrawer(false)}
          >
            <CloseIcon/>
          </IconButton>
        </div>

        <List className={classes.list}>
          {links.map((page, index) =>
            <ListItem button key={index} className={classes.listItem}>
              <NavLink
                to={page.link}
                exact={true}
                onClick={() => toggleDrawer(false)}
              >
                <Typography>{page.name}</Typography>
              </NavLink>
            </ListItem>
          )}
        </List>

      </div>
    </SwipeableDrawer>
  )
}

