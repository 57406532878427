import { API_URL } from "../constants";
import axios from "axios";

export async function auth(params) {
  console.log('AUTH', params)
  const response = await axios.post(`${API_URL}/CoachSchedule`, params)
  const data = await response;

  return data;
}

export async function auth_admin(params) {
  const response = await axios.post(`${API_URL}/AdminSchedule`, params)
  const data = await response;

  return data;
}

export async function auth_ski_patrol(params) {
  const response = await axios.post(`${API_URL}/Schedule`, params)
  const data = await response;

  return data;
}

