import React from "react";
import cn from "classnames";

import "./index.scss";

export const Input = (props) => {
  return (
    <input className={cn("form-input", {"form-input--error": props.isError})}
          {...props}
          />
  )
}
